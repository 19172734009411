<template>
  <v-row>
    <v-col cols="12" sm="10" md="10" lg="10">
      <v-row class="mt-2">
        <v-col class="pl-6">
          <v-row class="ml-3">
            <v-col cols="2" class="pr-0">
              <p class="mb-0" style="font-size: 18px; color: #424242">
                {{ $t("Type") }}:
              </p>
            </v-col>
            <v-col class="pl-0">
              <p class="mb-0" style="font-size: 18px; color: #a6cc39">
                {{ $t("sendnotification") }}
              </p>
              <v-checkbox
                class="mt-0"
                v-model="inbox"
                :label="$t('inbox')"
                color="#A6CC39"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="pushnoti"
                color="#A6CC39"
                :label="$t('pushnotification')"
                hide-details
              ></v-checkbox>
              <v-row no-gutters>
                <v-col cols="2">
                  <v-checkbox
                    class="pb-5"
                    v-model="email"
                    color="#A6CC39"
                    :label="$t('email')"
                    hide-details
                    @change="handleCheckboxChange"
                  ></v-checkbox>
                </v-col>
                <template>
                  <v-col class="d-flex justify-center align-center">
                    <v-row class="d-flex justify-center align-center">
                      <v-radio-group
                        v-model="selectedRadio"
                        class="radio-group"
                        :disable="!email"
                      >
                        <v-row rows="12" class="radiobutton">
                          <div class="radio-wrapper">
                            <div v-if="!email" class="radio-placeholder"></div>

                            <v-radio
                              v-else
                              value="thai"
                              class="radio-button"
                            ></v-radio>
                          </div>
                          <v-img
                            src="@/assets/thailandflag.svg"
                            width="35"
                            height="22"
                            class="ml-2"
                          ></v-img>
                          <div class="radio-wrapper ml-2">
                            <div v-if="!email" class="radio-placeholder"></div>
                            <v-radio v-else class="radio-button" value="eng">
                            </v-radio>
                          </div>
                          <v-img
                            src="@/assets/ukflag.svg"
                            width="35"
                            height="22"
                            class="ml-2"
                          ></v-img>
                        </v-row>
                      </v-radio-group>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="3" class="pr-0">
              <p class="mb-0" style="font-size: 18px; color: #424242">
                {{ $t("UserType") }}:
              </p>
            </v-col>
            <v-col class="pl-0">
              <p class="mb-0" style="font-size: 18px; color: #a6cc39">
                {{ $t("assigneduser") }}
              </p>
              <v-checkbox
                class="mt-0"
                v-model="requestall"
                :label="$t('all')"
                color="#A6CC39"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="requestnotfinished"
                color="#A6CC39"
                :label="$t('NotStarted')"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="text-align: right">
          <v-btn
            width="135"
            class="text-capitalize btnStyle mr-3 mb-3 btn_hover_effect"
            color="#A6CC39"
            @click="SentNotiAssignmentCourse()"
            >{{ $t("submit") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  props: {
    quizLocalId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    courseOfQuiz: [],
    selectedRadio: null,
    inbox: false,
    pushnoti: false,
    email: false,
    requestall: false,
    requestnotfinished: false,
    requestnotstarted: false,
  }),
  methods: {
    handleCheckboxChange() {
      if (this.email) {
        this.selectedRadio = "thai";
      } else {
        this.selectedRadio = null;
      }
    },
    async SentNotiAssignmentCourse() {
      let that = this;
      let course_saved = that.courseOfQuiz?.[0]?.id ?? null;
      if (course_saved == null && course_saved == undefined) {
        alert("Please select a course before sending this notification.");
        return;
      }

      if (
        that.inbox === false &&
        that.pushnoti === false &&
        that.email === false
      ) {
        alert("Please select the Send Notification type.");
        return;
      }

      if (
        that.requestall === false &&
        that.requestnotfinished === false &&
        that.requestnotstarted === false
      ) {
        alert("Please select Assigned User for sending notifications.");
        return;
      }

      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.quizLocalId,
        Inbox: that.inbox,
        PushNotification: that.pushnoti,
        Email: that.email,
        emailLanguage: that.selectedRadio,
        AssignedUserAll: that.requestall,
        AssignedUserNotFinished: that.requestnotfinished,
        AssignedUserNotStarted: that.requestnotstarted,
      };
      console.log("notification req, to check if quizId got it: ", request);
      await axios
        .post(
          `${that.web_url}SendNotification/SentNotiFromUserAssignmentCourseOfQuiz`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            alert("Send complete.");
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
      that.backToQuizPage(); //go back to main quiz table page
    },
  },
};
</script>

<style scoped>
.radio-group {
  display: flex;
  align-items: center;
}
.radiobutton {
  margin-top: -10px;
}
.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;

  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color: #424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}
</style>
