import jsPDF from "jspdf";

export default {


  removeToken(value) {
    if (!value) {
      // If value is null
      return null;
    }

    const searchString = "?";
    const index = value.indexOf(searchString);

    if (index !== -1) {
      // Remove everything after and including the '?' character
      return value.substring(0, index);
    } else {
      // No token found
      return value;
    }
  },

  async addTextToImageAndPdf(userName, baseImageItem, type, isPreview) {

    return new Promise((resolve, reject) => {
      // Create a new canvas inside the function for each call
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const imageUrl = baseImageItem?.templateImage;
      const positionArr = baseImageItem?.certificatePositions.filter(x => x.type === "full-name");

      // Load the image
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = imageUrl;

      img.onload = async () => {
        // Set canvas size to the image size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        const imageWidth = img.width;
        const imageHeight = img.height;

        // Loop through each position and draw the name
        positionArr.forEach(positionObj => {
          const nameX = Math.round((positionObj.x / 100) * imageWidth);
          const nameY = Math.round((positionObj.y / 100) * imageHeight);
          const textWidth = Math.round((positionObj.width / 100) * imageWidth);
          const textHeight = Math.round((positionObj.height / 100) * imageHeight);

          const fontSize = positionObj.fontSize ? positionObj.fontSize * 2 : 28; // Adjusted default font size
          const fontColor = positionObj.fontColor || 'black';

          const bold = positionObj?.fontBoldItalicUnderline.includes(0) ? 'bold' : 'normal';
          const italic = positionObj?.fontBoldItalicUnderline.includes(1) ? 'italic' : 'normal';
          const underline = positionObj?.fontBoldItalicUnderline.includes(2) ?? null;
          const fontFamily = positionObj.fontStyle || "Poppins, sans-serif";

          ctx.font = `${italic} ${bold} ${fontSize}px ${fontFamily}`;
          ctx.fillStyle = fontColor;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(userName, nameX + textWidth / 2, nameY + textHeight / 2);

          // Draw underline if needed
          if (underline) {
            const textMetrics = ctx.measureText(userName);
            const underlineY = nameY + (textHeight / 2) + 16;
            ctx.beginPath();
            ctx.moveTo(nameX + (textWidth / 2) - (textMetrics.width / 2), underlineY);
            ctx.lineTo(nameX + (textWidth / 2) + (textMetrics.width / 2), underlineY);
            ctx.lineWidth = 3;
            ctx.strokeStyle = fontColor;
            ctx.stroke();
          }
        });

        try {
          if (type === 'image') {
            // Generate and return the image as Base64
            const imageBase64 = canvas.toDataURL('image/jpeg', 0.7);
            const imgBlob = isPreview ? imageBase64 : this.base64ToBlob(imageBase64, 'image/jpeg');
            
            resolve(imgBlob);
          } else if (type === 'pdf') {
            // Generate PDF
            const imgData = canvas.toDataURL('image/jpeg', 0.7);
            const pdf = new jsPDF({
              orientation: imageWidth > imageHeight ? 'landscape' : 'portrait',
              unit: 'px',
              format: [imageWidth, imageHeight],
            });

            pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth, imageHeight, undefined, 'FAST');
            // Export PDF as Blob and upload it
            const pdfBlob = pdf.output('blob');            
            resolve(pdfBlob);            
          }
        } catch (uploadError) {
          reject(uploadError);
        }
      };

      img.onerror = (error) => {
        reject(`Failed to load image: ${error}`);
      };
    });
  },

  base64ToBlob(base64, mimeType) {
    const [header, data] = base64.split(',');
    const byteString = Buffer.from(data, 'base64'); // Use Buffer to decode base64
    const mimeString = mimeType || header.split(':')[1].split(';')[0];

    return new Blob([byteString], { type: mimeString });
  }

  // uploadFiles(url, formData) {
  //   return axios.post(url, formData)
  //     .then((response) => {                
  //       if (response.data.status === 0) {
  //         return response.data.data;
  //       }

  //       return "";
  //     })
  //     .catch((error) => {
  //       console.error("PDF upload error: ", error);
  //       return "";
  //     });
  // },

  // async uploadFile(url, data) {
  //   try {
  //     const response = await axios.post(url, data);
  //     return response.data ?? "";
  //   } catch (error) {
  //     console.error("File upload error: ", error);
  //     return "";
  //   }
  // },  

};